<template>
  <f7-page class="register-page">
    <meta name="robots" content="noindex" />

    <section class="register-container">
      <div class="container">
        <img src="@/assets/images/svg/success.svg" :alt="$t.getTranslation('LBL_CONGRATULATIONS')" loading="lazy" />
        <h1>{{ $t.getTranslation("LBL_CONGRATULATIONS") }}</h1>
        <p>{{ $t.getTranslation("LBL_CONGRATULATIONS_SUB") }}</p>
        <f7-button fill large raised @click="onLogin"> {{ $t.getTranslation("LBL_CONTINUE") }} </f7-button>
        <!-- SHOW HERE THE MESSAGE IF HAVE COUPON -->

        <span style="margin-top: 35px" v-if="referral.NewUserCoupon">{{ $t.getTranslation("LBL_GOT_COUPON_REGISTER") }}</span>
        <span style="margin-top: 35px" v-if="referral.ReferralUser">{{ $t.getTranslation("LBL_REFERRER") }}: {{ $h.renderUserName(referral.ReferralUser) }}</span>
      </div>
    </section>
  </f7-page>
</template>

<script>
import { defineComponent, onMounted, ref } from "vue";
import { login as authLogin } from "@/logics/auth.js";
import { useStore } from "@/store";

export default defineComponent({
  name: "RegisterSuccessPage",
  components: {},
  props: { f7router: Object },
  setup(props) {
    const store = useStore();

    let referral = ref(store.getters["referral/getData"]);

    onMounted(() => {
      store.dispatch("user/setData", { IsCompleted: true });
      store.dispatch("referral/setData", { NewUserCoupon: false, ReferralCode: null, ReferralUser: null });
    });
    const onLogin = async () => {
      props.f7router.navigate("/home/explorepjf/", { reloadCurrent: true });
    };

    return { onLogin, referral };
  },
});
</script>
<style scoped>
.register-container img {
  margin-top: 120px;
}
</style>
